import { mapGetters } from 'vuex';
//contants
import { EventConstants } from '@/constants/event';

export default {
  data: function() {
    return {
      searchActionShopPoint: 'parentPoint/getShopPointList',
      resetOptionShopPoint: 'parentPoint/RESET_SHOP_POINT_LIST',
      parentStoreModule: 'parentPoint',
      childStoreModule: 'childPoint',
      parentNavs: [
        {
          id: '01',
          name: 'ポイント履歴',
          path: '/event/point/history',
          routeName: 'EventParentPointHistory',
          includes: false,
        },
        {
          id: '02',
          name: 'ポイント設定',
          path: '/event/point/setting',
          routeName: 'EventParentPointSet',
          includes: false,
        },
        {
          id: '03',
          name: '店舗ポイント設定',
          path: '/event/point/store',
          routeName: 'EventParentPointStore',
          includes: false,
        },
        {
          id: '04',
          name: 'QR管理',
          path: '/event/point/qr/list',
          routeName: 'EventParentPointQR',
          includes: true,
        },
        {
          id: '05',
          name: 'ワンタイムトークン',
          path: '/event/point/onetime-token',
          routeName: 'EventParentPointOnetimeToken',
          includes: false,
        },
        {
          id: '06',
          name: 'ポイント集計',
          path: '/event/point/summary',
          routeName: 'EventParentPointSummary',
          includes: false,
        },
        {
          id: '07',
          name: 'ポイント管理',
          path: '/event/point/manage',
          routeName: 'EventParentPointManagement',
          includes: false,
        },
			],
			childNavs: [
        {
          id: '01',
          name: 'ポイント履歴',
          path: '/event/point/history',
          routeName: 'EventChildPointHistory',
          includes: false,
        },
        {
          id: '02',
          name: 'ポイント設定',
          path: '/event/point/setting',
          routeName: 'EventChildPointSet',
          includes: false,
        },
        {
          id: '03',
          name: '店舗ポイント設定',
          path: '/event/point/store',
          routeName: 'EventChildPointStore',
          includes: false,
        },
        {
          id: '04',
          name: 'QR管理',
          path: '/event/point/qr/list',
          routeName: 'EventChildPointQR',
          includes: true,
        },
        {
          id: '05',
          name: 'ワンタイムトークン',
          path: '/event/point/onetime-token',
          routeName: 'EventChildPointOnetimeToken',
          includes: false,
        },
        {
          id: '06',
          name: 'ポイント集計',
          path: '/event/point/summary',
          routeName: 'EventChildPointSummary',
          includes: false,
        },
        {
          id: '07',
          name: 'ポイント管理',
          path: '/event/point/manage',
          routeName: 'EventChildPointManagement',
          includes: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      hasShopRole: 'auth/hasShopRole',
      parentPaymentStoreFlag: 'parentPoint/parentPaymentStoreFlag',
      childPaymentStoreFlag: 'childPoint/childPaymentStoreFlag',
      isClientChild: 'auth/isClientChild',
      hasCustomersRole: 'auth/hasCustomersRole',
      parentCategoriesList: 'parentPoint/categoriesList',
      childCategoriesList: 'childPoint/categoriesList',
      parentPointDetail: 'parentPoint/parentPointDetail',
      childPointDetail: 'childPoint/childPointDetail',
      childPointQrDetail: 'childPoint/pointQrDetail',
      parentPointQrDetail: 'parentPoint/pointQrDetail',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
      childReleaseOtpList: 'childPoint/releaseOtpList',
      childReleaseOtpCount: 'childPoint/releaseOtpCount',
      parentReleaseOtpList: 'parentPoint/releaseOtpList',
      parentReleaseOtpCount: 'parentPoint/releaseOtpCount',
      parentQrTicketList: 'parentPoint/allQrTicketList',
      childQrTicketList: 'childPoint/allQrTicketList',
      parentOtpList: 'parentPoint/otpList',
      parentOtpCount: 'parentPoint/otpCount',
      parentOtpInactiveCount: 'parentPoint/otpInactiveCount',
      childOtpList: 'childPoint/otpList',
      childOtpCount: 'childPoint/otpCount',
      childOtpInactiveCount: 'childPoint/otpInactiveCount',
    }),
    categoriesList() {
      return this.isPointCommon ? this.parentCategoriesList : this.childCategoriesList;
    },
    subdomain() {
      if (this.isGmoOrOfficeLoginStore) return this.newSubdomain;
      return this.$permission.isStoreOrGroup() ? this.infor.event?.subdomain : this.$route.params?.subdomain;
    },
    directory() {
      return this.$route.params?.directory;
    },
    isPointCommon() {
      return !this.$route.params.directory;
    },
    isNotChildChildAndPointCommon() {
      return !(this.isClientChild && !this.isPointCommon);
    },
    isPaymentStoreFlag() {
      return this.isPointCommon ? this.parentPaymentStoreFlag : this.childPaymentStoreFlag;
    },
    pointDetail() {
      return this.isPointCommon ? this.parentPointDetail : this.childPointDetail;
    },
    pointQrDetail() {
      return this.isPointCommon ? this.parentPointQrDetail : this.childPointQrDetail;
    },
    pageNavs() {
      const navs = this.isPointCommon ? this.parentNavs : this.childNavs;
      const eventPointStore = this.isPointCommon ? 'EventParentPointStore' : 'EventChildPointStore';
      const eventManagement = this.isPointCommon ? 'EventParentPointManagement' : 'EventChildPointManagement';
      return navs.filter(e => {
        const eventPointStoreCondition = (!this.hasShopRole || !this.isPaymentStoreFlag) && e.routeName === eventPointStore;
        const eventManagementCondition = (this.isClientChild || !this.hasCustomersRole) && e.routeName === eventManagement;
        return !eventPointStoreCondition && !eventManagementCondition;
      })
    },
    modulePath() {
      return this.isPointCommon ? this.parentModulePath : this.childModulePath;
    },
    storeModule() {
      return this.isPointCommon ? this.parentStoreModule : this.childStoreModule;
    },
    searchKeyLength() {
      return EventConstants.SEARCH_KEY_MIN_LENGTH;
    },
    shopParams() {
      return {
        ...(!this.isPointCommon && { directory: this.directory }),
        ...(this.isGmoOrOfficeLoginStoreAndStoreGroup && { subdomain: this.subdomain }),
        ...(['EventParentPointQRRegister', 'EventChildPointQRRegister'].includes(this.$route.name) && { validFlag: true, openFlag: true })
      }
    },
    qrTicketList() {
      return this.isPointCommon ? this.parentQrTicketList : this.childQrTicketList;
    },
    searchAction() {
      const action = this.isPointCommon ? 'getParentAllQrTicketList' : 'getChildAllQrTicketList';
      return `${this.storeModule}/${action}`;
    },
    resetOptions() {
      return `${this.storeModule}/RESET_ALL_QR_TICKET_LIST`;
    },
  },
  created() {
    if (this.isGmoOrOfficeLoginStoreAndStoreGroup) return;
    if (this.isPointCommon) {
      this.$store.dispatch('parentPoint/getPointDetail');
    } else {
      this.$store.dispatch('childPoint/getPointDetail');
    }
  },
  methods: {
    getSelectDataList(action) {
      return this.$store.dispatch(`parentPoint/${action}`, {
        ...(!this.isPointCommon && { directory: this.directory }),
        ...(this.isGmoOrOfficeLoginStoreAndStoreGroup && { subdomain: this.subdomain })
      });
    },
    getCategories() {
      return this.$store.dispatch(`${this.storeModule}/getCategoriesList`, {
        limit: 50
      });
    }
  },
};
